import React, { Component } from "react";
import "./About.css";

class About extends Component {
  render() {
    return (
      <div className="container max-w-5xl mx-auto px-8 lg:px-0">
        <div className="pt-12 pb-6">
          <h1>
            About Me
          </h1>
          <div className="grid grid-cols-1 sm-custom-grid" style={{justifyItems: 'flex-end'}}>
            <div>
              <p>My name is Parth Patel and I am a full stack developer and a tech blogger. I started this blog to document everything I know and everything I am learning along the way.</p>
              <p>I like to teach people and have experience peer tutoring my juniors in college. I guess that's the reason I am so fond of blogging.</p>
              <p>I want to help as many people possible in their programming careers and I hope I can make a difference.</p>
            </div>
            <div className="sm:block">
              <picture>
                <source srcSet="img/parthpatel.webp" type="image/webp" alt="Parth Patel" width="300px" height="300px" style={{borderRadius: "100%"}} />
                <img src="/img/parthpatel.jpeg" alt="Parth Patel" width="300px" height="300px" style={{borderRadius: "100%"}} />
              </picture>
            </div>
          </div>
          

          Here are a few random things I like:
          <ul>
            <li>📚  Books: Harry Potter Series, The Sapiens, Atomic Habits</li>
            <li>🎵  Songs: It keeps changing everyday: But right now - Sunday Best</li>
            <li>🎮  Games: CS GO, Marvel's Spiderman on PS4</li>
            <li>Activities: Pretty much anything adventurous - Hiking, Sky diving, Bunjee Jumping, Zip Lining, Scuba Diving (haven't tried yet)</li>
          </ul>

          <h2>Currently Using</h2>
          <ul>
          <li>Computer: 15″ MacBook Pro</li>
          <li>Monitor: Samsung 27inch Curved</li>
          <li>Keyboard: Logitech G513</li>
          <li>Mouse: Logitech G502 Lightspeed Wireless</li>
          <li>Note taking: Goodnotes on iPad Pro 11.9 Inch</li>
          <li>Hosting: Netlify</li>
          <li>Editor: Visual Studio Code</li>
          <li>Blog: Gatsby, TailwindCSS</li>
          <li>Syntax highlighting: PrismJS</li>
          </ul>

          <h2>Get in touch</h2>
          If there's anything you want to talk about or need help with or just wanna say Hi, feel free to contact me via email below.
          <a href="mailto:parth@parthpatel.net"><h1>parth@parthpatel.net</h1></a>
          <ul>
            <li>🐦  Twitter: <a href="https://twitter.com/parthp1808">@parthp1808</a></li>
          </ul>
        </div>
      </div>
    );
  }
}

export default About;
